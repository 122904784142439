import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-5" }
const _hoisted_2 = { class: "mb-5 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateCoinRateForm = _resolveComponent("CreateCoinRateForm")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, "Create Coin Rate for " + _toDisplayString(_ctx.coinDetail.name), 1),
      _cache[0] || (_cache[0] = _createElementVNode("div", null, [
        _createElementVNode("small", null, "Coins rates here are available for users.")
      ], -1))
    ]),
    _createVNode(_component_CreateCoinRateForm)
  ]))
}