
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { apiGet } from "@/api";
import CreateCoinRateForm from "@/views/coins/constituent/CreateCoinRateForm.vue";

export default defineComponent({
  name: "CreateCoinRate",
  components: {
    CreateCoinRateForm,
  },
  setup() {
    const loading = ref(false);
    const coinDetail = ref({});
    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/coin/${route.params.coinRateId}/coin-types`
        );

        coinDetail.value = response.data.data.coinType;

        // console.log("...coin detail", coinDetail.value);
      } catch (e:any) {
        console.log("...error", e);
      }
      loading.value = false;
    };
    return {
      loading,
      coinDetail,
      RefreshData,
    };
  },

  mounted() {
    this.RefreshData();
  },
});
